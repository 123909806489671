import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {modalModifShow,modif,update} from './promoSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const promoData = useSelector(state => state.promosData);
    const listProduit = promoData.listProduit
    const showModal = promoData.modalModifShow;
    const currentelement = promoData.current;

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const [valProduitCible, setvalProduitCible] = useState('');
    const [valProduitPromo, setvalProduitPromo] = useState('');
    const dispatch= useDispatch();

    const qteCibleField = useRef('')
    const qtePromoField = useRef('')
    const remarqueField = useRef('')

    const handleProdCibleChange = (event) => {
      setvalProduitCible(event.target.value);
    };

    const handleProdPromoChange = (event) => {
      setvalProduitPromo(event.target.value);
    };

      const handleClose = () => {
        dispatch(modalModifShow(false));
      };

      const Ajouter =async()=>{
        let qtecible =qteCibleField.current.value
        qtecible=qtecible.replace(",", ".");

        let qtepromo =qtePromoField.current.value
        qtepromo=qtepromo.replace(",", ".");

        const remarque = remarqueField.current.value.trim()

        if(valProduitCible==="" || valProduitPromo==="" || remarque==="" || qtecible==="" || qtepromo===""){
          alert("Veuillez remplir les champs manquants");
          return
        }
        let valeur = listProduit.find(prod => prod._id === valProduitCible);
        const prodCible = {
          id: valeur._id,
          designation: valeur.designation
        };
        valeur = listProduit.find(prod => prod._id === valProduitPromo);
        const prodPromo = {
          id: valeur._id,
          designation: valeur.designation
        };
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        const idpromo = currentelement._id
        const etat = currentelement.etat
        try {
            const response = await axios.post(`${apiUrl}edit/promo/${id}/${apitoken}/${idpromo}`,
                {
                  prodCible,
                  qtecible,
                  prodPromo,
                  qtepromo,
                  remarque,
                  etat
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const data={idpromo,prodCible,qtecible,prodPromo,qtepromo,remarque,etat};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }
    useEffect(() =>{
      if(Object.keys(currentelement).length>0)
      {
        setvalProduitCible(currentelement.prodCible["id"]);
        setvalProduitPromo(currentelement.prodPromo["id"])
    }
      },[currentelement]);
  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier une promotion</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez modifier fiche de la promotion
          </DialogContentText>
          <Box mt={2}>
         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Produit cible</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         required
         value={valProduitCible}
         label="Produit cible"
         onChange={handleProdCibleChange}
       >
         {listProduit.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

         <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="qtepromo"
            name="quantite"
            label="Quantité Cible"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={qteCibleField}
            defaultValue={currentelement.qtecible}
          />
          </Box>

</Box>
<Box mt={2}>
         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Produit promo</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         required
         value={valProduitPromo}
         label="Produit promo"
         onChange={handleProdPromoChange}
       >
         {listProduit.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

</Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="quantite"
            label="Quantité Promo"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={qtePromoField}
            defaultValue={currentelement.qtepromo}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="designation"
            label="Désignation"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={remarqueField}
            defaultValue={currentelement.remarque}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
