import { Box, Container,   Skeleton, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

export default function Caisse() {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      
    const [Chargement, setChargement] = useState(false);

    const [listCaisse,setListCaisse] = useState([]);

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;
  
    const [selectedDate, setSelectedDate] = useState(todayDate);

    const dateField=useRef('')
    
    const navigate = useNavigate();

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };


      const formatDate = (dateString) => {
        return dateString.replace(/-/g, '/');
      };

      const formatDateToFrench = (dateString) => {
        const dateObj = new Date(dateString);
        const dateFrench = dateObj.toLocaleDateString('fr-FR');
          return dateFrench;
      };


    const userData = useSelector(state => state.userData)
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;
  

    const calculateEspeceByDate = (chosenDate) => {
      // Filtrer les données selon la date choisie
      const filteredData = listCaisse.filter(item => item.date === chosenDate);
    
      // Calculer la somme des espèces
      const totalEspece = filteredData.reduce((acc, current) => acc + current.espece, 0);
    
      return totalEspece;
    };

    function ShowList() {
        if(listCaisse.length > 0){
          const totalEspece = calculateEspeceByDate(formatDate(selectedDate));
        
            return(
              <StyledTableRow>
      
              <StyledTableCell component="th" scope="row">
                {totalEspece+" Dhs"}
              </StyledTableCell>

              <StyledTableCell component="th" scope="row">
              {formatDateToFrench(selectedDate)}
              </StyledTableCell>
            </StyledTableRow>
            )
    
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={2} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

      const getCaisse=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}versements/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListCaisse(response.data)
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

    useEffect(() =>{
        getCaisse();
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
        
        <Typography variant="h4" align='center' color="primary">
        Caisse
        </Typography>
        <Box display="flex" width="100%" mt={5}>
<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }} variant="rounded" height={56} width={150} />
         :
         <TextField
         inputRef={dateField}
         value={selectedDate}
         onChange={handleDateChange}
         id="input-with-icon-textfield"
         label="Date"
         size='normal'
         type='date'
     variant="outlined"
   />  
}
</Box>

            </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Montant</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
    </Container>
  )
}
