import { Alert, Box, Button, Chip, Container, LinearProgress, Skeleton, Snackbar, Stack, Switch,  Typography } from '@mui/material'
import React, { useEffect,  useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,getList,remove,modalModifShow,current,modif,getProdList,changeEtat} from './promoSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Ajouter from './Ajouter';
import Modifier from './Modifier';


export default function Promos() {
  const userData = useSelector(state => state.userData);
  const [Chargement, setChargement] = useState(false);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const promoData=useSelector(state => state.promosData);
  const listPromos=promoData.list
  const ajoutSuccess = promoData.ajoutOk
  const modifSuccess = promoData.modifOk
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [etatSuccess, setEtatSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);

  const dispatch= useDispatch();
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajout(false));
    dispatch(modif(false));
    setRemoveSuccess(false);
    setEtatSuccess(false)
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const suppression = async (idpromo)=> {
        setProgession(true);

        try {
            const response = await axios.post(`${apiUrl}delete/promo/${id}/${apitoken}/${idpromo}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idpromo));
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
    
      const supprimer = (idpromo) => {
        let result = window.confirm(`Supprimer la promotion ?`);
        if (result === true) {
            suppression(idpromo);
        }
    }

    const modifier = (id) => {
      dispatch(modalModifShow(true));
      const valeur = listPromos.find(client => client._id === id);
      dispatch(current(valeur));
  };

  const modifierEtat = async (idpromo,etatpromo)=> {
    setProgession(true);
    try {
      const etat = etatpromo
        const response = await axios.post(`${apiUrl}edit/promo/${id}/${apitoken}/${idpromo}`,
          {
            etat
           },
          {
            timeout: 20000,
          }
        );
        if(response.data.status==="ok")
            {
                const data = {idpromo,etat} 
                dispatch(changeEtat(data));
                setEtatSuccess(true);
                setProgession(false);
            }
    } catch (error) {
      // navigate('/');
    }
}
      function ShowList() {
        if(listPromos.length > 0){
          let listFiltre=listPromos
          return listFiltre.map(Promo => {
            return(
              <StyledTableRow key={Promo._id}>
              <StyledTableCell component="th" scope="row">
              <Chip variant="outlined" sx={{ marginRight: '10px' }} label={Promo.qtecible} />{Promo.prodCible.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <Chip variant="outlined" sx={{ marginRight: '10px' }} label={Promo.qtepromo} />{Promo.prodPromo.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Promo.remarque}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Promo.etat==="Active"?
              <Switch onClick={() => modifierEtat(Promo._id,"Inactive")}  defaultChecked />
              :
              <Switch onClick={() => modifierEtat(Promo._id,"Active")}  />
              }
              </StyledTableCell>
              <StyledTableCell align="right">
              <Button variant="outlined" color='secondary' startIcon={<EditIcon />} onClick={() => modifier(Promo._id)}>
               Modifier
              </Button>      
              <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Promo._id)}>
               Supprimer
              </Button>
              </StyledTableCell>
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={5} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }
        

      const getPromo=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}promos/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getList(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

    const getProduits=async () => {
      setChargement(true);
      try {
      const response = await axios.post(`${apiUrl}produits/${id}/${apitoken}`,
        {
          timeout: 20000,
        }
      );
      dispatch(getProdList(response.data));
      }
   catch (error) {
    navigate('/');    
  }
  setChargement(false);
  }

      useEffect(() =>{
        getPromo();
        getProduits();
        dispatch(showmodal(false));
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Promotions
        </Typography>

        <Box mt={4} sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={() =>dispatch(showmodal(true))} >
    Ajouter une promotion
  </Button> 
}  
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Produit Cible</StyledTableCell>
            <StyledTableCell>Produit Promo</StyledTableCell>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Etat</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
    <Ajouter/>
    <Modifier/>

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Promotion ajoutée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Promotion modifiée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Promotion supprimée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={etatSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Etat modifiée avec succès
        </Alert>
      </Snackbar>
        </Container>
  )
}