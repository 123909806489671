import { Alert, Box, Button, Chip, Container, InputAdornment, LinearProgress, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,getList,remove,modalModifShow,current,modif} from './vendeurSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Ajouter from './Ajouter';
import Modifier from './Modifier';

import SearchIcon from '@mui/icons-material/Search';

export default function Vendeurs() {
  const userData = useSelector(state => state.userData);
  const [Chargement, setChargement] = useState(false);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const vendeurData=useSelector(state => state.vendeursData);
  const listVendeur=vendeurData.list
  const ajoutSuccess = vendeurData.ajoutOk
  const modifSuccess = vendeurData.modifOk
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);
  const [rechercheInput,setRchercheInput] = useState('');
  const [reset2faSuccess, setreset2faSuccess] = useState(false);
  const [resetPassSuccess, setresetPassSuccess] = useState(false);

  const rechercheField = useRef('')

  const dispatch= useDispatch();
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajout(false));
    dispatch(modif(false));
    setRemoveSuccess(false);
    setreset2faSuccess(false);
    setresetPassSuccess(false);
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const suppression = async (idvendeur)=> {
        setProgession(true);

        try {
            const response = await axios.post(`${apiUrl}delete/vendeur/${id}/${apitoken}/${idvendeur}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idvendeur));
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
    
      const supprimer = (iduser,nom) => {
        let result = window.confirm(`Supprimer le vendeur ${nom} ?`);
        if (result === true) {
            suppression(iduser);
        }
    }

    const modifier = (id) => {
      const valeur = listVendeur.find(vendeur => vendeur._id === id);
      dispatch(current(valeur));
      dispatch(modalModifShow(true));
  };

  const reset2FA = async (iduser)=> {
    setProgession(true);

    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}reset/2fa/${id}/${apitoken}/${iduser}`,
          {
            timeout: 20000,
          }
        );
        if(response.data.status==="ok")
            {
                setreset2faSuccess(true);
            }
    } catch (error) {
      navigate('/');
    }
    setProgession(false);
}

  const reset2faConfirm = (iduser,nom) => {
    let result = window.confirm(`Réinitialiser le 2FA de ${nom} ?`);
    if (result === true) {
        reset2FA(iduser);
    }
}

const resetPassword = async (iduser)=> {
  setProgession(true);

  try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}reset/password/${id}/${apitoken}/${iduser}`,

        {
          timeout: 20000,
        }
      );
      if(response.data.status==="ok")
          {
              setresetPassSuccess(true);
          }
  } catch (error) {
    navigate('/');
  }
  setProgession(false);
}

const resetPasswordConfirm = (iduser,nom) => {
  let result = window.confirm(`Réinitialiser le mot de passe de ${nom} ?`);
  if (result === true) {
      resetPassword(iduser);
  }
}

      function ShowList() {
        if(listVendeur.length > 0){
          let listFiltre=listVendeur
          if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(vendeur => vendeur.nom.toLowerCase().includes(rechercheInput.toLowerCase()))
        }
          return listFiltre.map(Vendeur => {
            return(
              <StyledTableRow key={Vendeur._id}>
              <StyledTableCell component="th" scope="row">
                {Vendeur.nom}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Vendeur.tel}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">

              <Box maxWidth={300} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {Vendeur.secteurs.map( secteur =>{
                  return(
                    <Chip label={secteur.designation} variant="outlined" />
                  )
                }

                )
                }
                </Box>
           
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Vendeur.salaire}
              </StyledTableCell>
              <StyledTableCell>
        <Stack direction="row" spacing={1} justifyContent={'center'}>
          {Vendeur.forceTotp === "Oui" ?
          <Chip label="Oui" color="success" />
          :
          <Chip label="Non" color="warning" />
          }
          </Stack>
          <Stack direction="row" spacing={1}></Stack>

        </StyledTableCell>
              <StyledTableCell align="right">
                <Box>
              <Button variant="outlined" color='secondary' startIcon={<EditIcon />} onClick={() => modifier(Vendeur._id)}>
               Modifier
              </Button>    
              <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Vendeur._id,Vendeur.nom)}>
               Supprimer
              </Button>
              </Box>
              <Box mt={1}>
              <Button variant="outlined" color='warning' startIcon={<RestartAltIcon />} onClick={() => reset2faConfirm(Vendeur._id,Vendeur.nom)}>
              Reset 2FA
              </Button> 
              <Button sx={{ marginLeft: '10px' }} variant="outlined" color='warning' startIcon={<RestartAltIcon />} onClick={() => resetPasswordConfirm(Vendeur._id,Vendeur.nom)}>
              Reset MDP
              </Button> 
              </Box>
              </StyledTableCell>
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={6} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

      const getVendeurs=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}vendeurs/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getList(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }
    const Recherche=(event) =>{
      const recherche=rechercheField.current.value
      if(recherche!==rechercheInput){
        setRchercheInput(recherche)
         }

    }

      useEffect(() =>{
        getVendeurs();
        dispatch(showmodal(false));
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Vendeurs
        </Typography>

        <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheField}
        id="input-with-icon-textfield"
        label="Recherche"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
       }
                {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={() =>dispatch(showmodal(true))} >
    Ajouter un Vendeur
  </Button>   
    }
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>N° Tel.</StyledTableCell>
            <StyledTableCell>Secteurs</StyledTableCell>
            <StyledTableCell>Salaire</StyledTableCell>
            <StyledTableCell>Protection 2FA</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
          <Ajouter/>
          <Modifier/>

          <Snackbar open={reset2faSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          2FA réinitialisé avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={resetPassSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Mot de passe réinitialisé avec succès
        </Alert>
      </Snackbar>

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Vendeur ajouté avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Vendeur modifié avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Vendeur supprimé avec succès
        </Alert>
      </Snackbar>
        </Container>
  )
}
