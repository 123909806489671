import {  Button, Container, InputAdornment, Paper, Skeleton, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {ajoutsuccess,showapercu} from './venteSlice';
import Apercu from './Apercu';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export default function NouvelleVente() {
    const userData = useSelector(state => state.userData);
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;
    const venteData=useSelector(state => state.PdanewventeData);
    const currentClient = venteData.current

    const [Chargement, setChargement] = useState(false);
    const [valCategorie, setCategorie] = useState('Tout');
    const [valMarque, setMarque] = useState('Tout');
    const [listProduits, setListProduits] = useState([]);
    const [listMarque,setListMarque] = useState([]);
    const [listCat,setListCat] = useState([]);
    const [rechercheInput,setRchercheInput] = useState('');
    const [Progession, setProgession] = useState(false);
    const [listPromo, setlistPromo] = useState([]);
    const [selectedPromo, setselectedPromo] = useState([]);
    const [personName, setPersonName] = useState([]);
    const [etatRegement, setEtatRegement] = useState('');

    const rechercheField = useRef('')
    const reglementField = useRef('')
    const currentCamion = useRef('')
    const promosField = useRef('')
    const listAchats=useRef([]);
    const listPromoBrut = useRef([])

    const theme = useTheme();

    const handlePromoChange = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const dispatch= useDispatch();

    const ShowApercu =() =>{

      const tempPromo = promosField.current.value

      const promotions = tempPromo.map(remarque => {
        const match = listPromoBrut.current.find(item => item.remarque === remarque);
        if (match) {
          return {
            id: match._id,
            prodCible : match.prodCible,
            qtecible: match.qtecible,
            prodPromo : match.prodPromo,
            qtepromo : match.qtepromo,
            remarque : match.remarque,

          };
        }
        return null;
      }).filter(item => item !== null);

      setselectedPromo(promotions)

      dispatch(showapercu(true))
   }

   function calculatePromos(achats, promos) {
    const resultPromos = [];
  
    promos.forEach(promo => {

        // Trouver le produit cible dans les achats
        const produitCible = achats.find(achat => achat._id === promo.prodCible.id);
  
        if (produitCible) {
          // Calculer combien de fois la quantité cible est atteinte
          const nb_pack = GetPack(produitCible.totalunite,produitCible.qte_pack)

          const multiplier = Math.floor(nb_pack / parseInt(promo.qtecible, 10));
  
          // Si le multiplicateur est supérieur à 0, il y a une promo
          if (multiplier > 0) {
            // Ajouter le produit promo au tableau des promos
            resultPromos.push({
              _id: promo.prodPromo.id,
              nom_promo : promo.remarque,
              designation: promo.prodPromo.designation,
              qtepromo: multiplier * parseInt(promo.qtepromo, 10),
              totalunite : parseInt(promo.prodPromo.qte_pack) * (multiplier * parseInt(promo.qtepromo, 10))
            });
          }
        }

    });
    return resultPromos;
  }

   const calculateSum = (products) => {
    return products.reduce((total, product) => {
        if(product.totalunite === 0) {
            product.totalunite = 0
        }
        const tva = product.prix_vente * (20 / 100);
        return total + ((product.prix_vente + tva) * product.totalunite);
    
    }, 0);
};
    const StockUpdate2 = (value,idprod,event) => {
        const qte = value
        const id = idprod
  
        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                product.unite = qte
                const _id=product._id
                const designation=product.designation
                const pack=product.pack
                let unite = product.unite
                const qte_pack = product.qte_pack
                const marque = product.marque["id"]
                const categorie = product.categorie["id"]
                const prix_vente = product.prix_vente
                const prix_achat = product.prix_achat
                let totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
                if(totalunite>parseInt(product.totalunite)){
                  alert('Stock insuffisant')
                  event.target.value=0
                  product.unite = 0
                  unite=0
                  totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
                }
                const valeurs={_id,designation,pack,unite,totalunite,qte_pack,marque,categorie,prix_vente,prix_achat}
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock et les unités
                    listAchats.current[existingProductIndex].unite = unite;
                    listAchats.current[existingProductIndex].totalunite = totalunite;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                return { listAchats };
            }
            return product;
        });
        
    };

    const StockUpdate = (value,idprod,event) => {
        const qte = value;
        const id = idprod;

        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                product.pack = qte
                const _id=product._id
                const designation=product.designation
                let pack=product.pack
                const unite = product.unite
                const qte_pack = product.qte_pack
                const marque = product.marque
                const categorie = product.categorie
                const prix_vente = product.prix_vente
                const prix_achat = product.prix_achat
                let totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
                if(totalunite>parseInt(product.totalunite)){
                  alert('Stock insuffisant')
                  event.target.value=0
                  product.pack = 0
                  pack=0
                  totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
                }
                const valeurs={_id,designation,pack,unite,totalunite,qte_pack,marque,categorie,prix_vente,prix_achat}
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock et les unités
                    listAchats.current[existingProductIndex].pack = pack;
                    listAchats.current[existingProductIndex].totalunite = totalunite;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                return { listAchats };

           }
            return product;
        });
        
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    

    const navigate = useNavigate();
    
    const handleCategorieChange = (event) => {
        setCategorie(event.target.value);
      }

      const handleMarqueChange = (event) => {
        setMarque(event.target.value);
      };
      const handlePaiementChange = (event) => {
        setEtatRegement(event.target.value);
      };

      const getMarque=async () => {
        try {
        const response = await axios.post(`${apiUrl}marques/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListMarque(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    }
    const getCategorie=async () => {
        try {
        const response = await axios.post(`${apiUrl}categories/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListCat(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    }
    const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        if(recherche!==rechercheInput){
          setRchercheInput(recherche)
           }
      }

      const GetPack =(nb_unite,qte_pack) => Math.floor(nb_unite / qte_pack)

    function ShowList() {
        if(listProduits.length > 0){
          let listFiltre=listProduits
          if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(produit => produit.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
        }

        if(valMarque!=="Tout") {
            listFiltre = listFiltre.filter(produit => produit.marque === valMarque)
        }
  
        if(valCategorie!=="Tout") {
          listFiltre = listFiltre.filter(produit => produit.categorie === valCategorie)
      }
          return listFiltre.map(Produit => {
            const currentQte = listAchats.current.find(item => item._id === Produit._id);
            let stock="0";
            let unite="0";
            if(currentQte){
                stock=currentQte.pack;
                unite=currentQte.unite;
            }
            return(
              <StyledTableRow key={Produit._id}>
              <StyledTableCell component="th" scope="row" align="center">
              <Typography variant="h6" align='center' color="secondary">
              {Produit.designation}
                </Typography>
                <Box mt={1}>
                <TextField
       onChange={(event) => StockUpdate(event.target.value,Produit._id,event)}
        defaultValue={stock}
        id={Produit._id}
        label="Packs"
        size='small'
        type='number'
        variant="outlined"
      />
<Chip sx={{ marginTop: '2px',marginLeft:'10px' }} label={`Disponible : ${GetPack(Produit.totalunite,Produit.qte_pack)}`} />
        </Box>
        <Box mt={1}>
                <TextField
       onChange={(event) => StockUpdate2(event.target.value,Produit._id,event)}
        defaultValue={unite}
        id={Produit._id}
        label="Unités"
        size='small'
        type='number'
        variant="outlined"
      />
<Chip sx={{ marginTop: '2px',marginLeft:'10px' }} label={`Disponible : ${Produit.totalunite}`} />
        </Box>
              </StyledTableCell>
              
            </StyledTableRow>
            )
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }
        
    const getStock=async () => {
        setChargement(true)
        try {
        const response = await axios.post(`${apiUrl}pda/stockcamion/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        if(response.data["status"]==="ok")
        {
        const stockcamion = response.data["data"]["produits"]
        currentCamion.current = response.data["data"]["camion"]    

        
        const data = stockcamion.map(product => ({
            ...product,pack:0,unite: 0
          }));
        setListProduits(data);
      }
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false)
    }

    const removeZeroStock = (list) => {
        return list.current.filter(item => item.totalunite !== 0);
      };


      const getPromo=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}pda/promos/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        listPromoBrut.current = response.data
        setlistPromo(response.data.map(item => item.remarque))
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

    const Ajouter = async () => {
        const etat = reglementField.current.value
        if(etat==="")
        {
          alert("Veuillez mentionner l'état du paiement")
          return
        }

        const produits = removeZeroStock(listAchats)
        let total=calculateSum(listAchats.current)
        total = total.toFixed(2)

        const tempPromo = promosField.current.value

        const promos = tempPromo.map(remarque => {
          const match = listPromoBrut.current.find(item => item.remarque === remarque);
          if (match) {
            return {
              id: match._id,
              prodCible : match.prodCible,
              qtecible: match.qtecible,
              prodPromo : match.prodPromo,
              qtepromo : match.qtepromo,
              remarque : match.remarque,
  
            };
          }
          return null;
        }).filter(item => item !== null);
    
        const promotions = calculatePromos(listAchats.current, promos)
        const vendeur = {
            "id":userData.id,
            "nom" : userData.nom,
        }
        const client = {
            "id" : currentClient._id,
            "nom" : currentClient.nom,
            "prenom" : currentClient.prenom,
            "client_code" : currentClient.client_code
        }
        const secteur = currentClient.secteur
        const camion = currentCamion.current
        setProgession(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}pda/new/vente/${id}/${apitoken}`,
          {
                vendeur,
                client,
                camion,
                secteur,
                produits,
                total,
                promotions,
                etat

            },
            {
              timeout: 20000,
            }
          );
          if(response.data.status==="ok") {
            dispatch(ajoutsuccess(true));
            navigate('/home/PDArechercheclient', { replace: true });
          }
          else {
            alert("Journée clôturée")
          }
        }
     catch (error) {
      navigate('/'); 
    }
    setProgession(false);
    }   
      useEffect(() =>{
        getStock();
        getMarque();
        getCategorie();
        getPromo();
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Nouvelle vente
        </Typography>
        <Typography mt={1} variant="h6" align='center' color="secondary">
                {currentClient.nom} {currentClient.prenom}
                </Typography>
                <Box alignContent={'center'} align={'center'} justifyContent={'center'}>
                {currentClient.client_code}

                <Box mt={1} width={'100%'} alignContent={'center'} justifyContent={'center'}>
          <Stack direction="row" spacing={1} justifyContent={'center'}>
         <Chip label={currentClient.secteur["designation"]} />
         </Stack>
          </Box>
        </Box>

        <Box mt={2}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} fullWidth />
         :
<FormControl fullWidth size='small'>
          <InputLabel id="demo-simple-select-label">Catégories</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégories"
          onChange={handleCategorieChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {listCat.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
}
</Box>

<Box mt={2}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} fullWidth />
         :
<FormControl fullWidth size='small'>
          <InputLabel id="demo-simple-select-label">Marques</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select2"
          value={valMarque}
          label="Marques"
          onChange={handleMarqueChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {listMarque.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
}
</Box>
<Box mt={2}>
{Chargement ? 
        <Skeleton  variant="rounded" height={56} fullWidth />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheField}
        id="input-with-icon-textfield"
        label="Recherche"
        size='small'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      
       }
</Box>
<Box mt={2}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} fullWidth />
         :
          <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">Promotions</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handlePromoChange}
          inputRef={promosField}
          input={<OutlinedInput id="select-multiple-chip" label="Promotions" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {listPromo?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      }
      </Box>
      <Box mt={2}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} fullWidth />
         :
<FormControl fullWidth size='small'>
          <InputLabel id="demo-simple-select-label">État de paiement</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select2"
          value={etatRegement}
          label="État de paiement"
          onChange={handlePaiementChange}
          inputRef={reglementField}

        >
          <MenuItem value='Payé'>Payé</MenuItem>
          <MenuItem value='Crédit'>Crédit</MenuItem>
          </Select>
          </FormControl>
}
</Box>

{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Liste produits</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
<Box mt={2} fullWidth>

{Chargement ? 
<Skeleton  variant="rounded" height={56} fullWidth />
:

<Button fullWidth variant="contained" size='normal' color="primary" startIcon={<RemoveRedEyeOutlinedIcon/>} onClick={ShowApercu}  >
Aperçu
</Button>  
} 
</Box>
<Box mt={2} fullWidth>
{Chargement ? 
<Skeleton   variant="rounded" height={56} fullWidth />
:

<Button variant="contained" fullWidth disabled={Progession} size='normal' color="success" startIcon={<CloudDoneOutlinedIcon/>} onClick={Ajouter} >
Enregistrer
</Button>
} 
</Box>
<Apercu Chargements={listAchats} Total={calculateSum(listAchats.current)} Promos = {calculatePromos(listAchats.current, selectedPromo)} />
    </Container>
  )
}
