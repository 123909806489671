import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,addOne} from './clientSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const clientsData = useSelector(state => state.clientsData);
    const showModal = clientsData.modalShow;
    const listSecteur=clientsData.listSecteur
    const [valSecteur, setvalSecteur] = useState('');
    const [valBanque, setvalBanque] = useState('AL AKHDAR BANK');

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const nomField = useRef('')
    const prenomField = useRef('')
    const telField = useRef('')
    const villeField = useRef('')
    const iceField = useRef('')
    const compteField = useRef('')

    const handleSecteurChange = (event) => {
      setvalSecteur(event.target.value);
    };
    const handleBanqueChange = (event) => {
      setvalBanque(event.target.value);
    };
      const handleClose = () => {
        dispatch(showmodal(false));
      };
      const Ajouter =async()=>{
        const nom =nomField.current.value.trim()
        const prenom = prenomField.current.value.trim()
        if(nom===""){
          alert("Champ nom vide");
          return
      }

      if(valSecteur===""){
        alert("Champ secteur vide");
        return
    }

        const ville = villeField.current.value
        const ice = iceField.current.value
        const tel = telField.current.value
        const valeur = listSecteur.find(secteur => secteur._id === valSecteur);

        const secteur = {
          id: valeur._id,
          designation: valeur.designation
        };
        
        const banque= valBanque
        const compte = compteField.current.value

        const location ={}

        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}new/client/${id}/${apitoken}`,
                {
                  nom,
                  prenom,
                  tel,
                  ice,
                  ville,
                  secteur,
                  banque,
                  compte,
                  location,
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const client_code = response.data.client_code;
                    const data={_id,client_code,nom,prenom,tel,ice,ville,secteur,banque,compte};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    
                }
            else if(response.data.status==="exist")
             {
                alert("Client déja existant");
            }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Ajouter une Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez remplir la fiche du nouveau client
          </DialogContentText>
          <TextField sx={{ marginTop: '20px' }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="Nom"
            label="Nom"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={nomField}
          />
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="prenom"
            name="prenom"
            label="Prénom"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={prenomField}
          />
            <TextField
            autoFocus
            margin="dense"
            id="tel"
            name="tel"
            label="Tel."
            type="text"
            fullWidth
            variant="outlined"
            inputRef={telField}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ville"
            name="ville"
            label="Ville"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={villeField}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ice"
            name="ice"
            label="ICE"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={iceField}
          />

          </Box>
          <Box mt={2}>

         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Secteur</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         value={valSecteur}
         label="Marque"
         onChange={handleSecteurChange}
       >
         {listSecteur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

</Box>
<Box mt={2}>

<FormControl fullWidth sx={{ minWidth: 150 }}>
<InputLabel id="demo-simple-select-label">Banque</InputLabel>
<Select

labelId="demo-simple-select-label"
id="demo-simple-select"
fullWidth
value={valBanque}
label="Banque"
onChange={handleBanqueChange}
>

<MenuItem value="AL AKHDAR BANK">AL AKHDAR BANK</MenuItem>
<MenuItem value="AL BARID BANK">AL BARID BANK</MenuItem>
<MenuItem value="ARAB BANK">ARAB BANK</MenuItem>
<MenuItem value="ATTIJARIWAFA BANK">ATTIJARIWAFA BANK</MenuItem>
<MenuItem value="BANK AL YOUSR">BANK AL YOUSR</MenuItem>
<MenuItem value="BANK ASSAFA">BANK ASSAFA</MenuItem>
<MenuItem value="BANK OF AFRICA">BANK OF AFRICA</MenuItem>
<MenuItem value="BANQUE CENTRALE POPULAIRE">BANQUE CENTRALE POPULAIRE</MenuItem>
<MenuItem value="BMCI">BMCI</MenuItem>
<MenuItem value="BTI BANK">BTI BANK</MenuItem>
<MenuItem value="CDG CAPITAL">CDG CAPITAL</MenuItem>
<MenuItem value="CFG BANK">CFG BANK</MenuItem>
<MenuItem value="CIH BANK">CIH BANK</MenuItem>
<MenuItem value="CITIBANK MAGHREB">CITIBANK MAGHREB</MenuItem>
<MenuItem value="CREDIT AGRICOLE">CREDIT AGRICOLE</MenuItem>
<MenuItem value="CREDIT DU MAROC">CREDIT DU MAROC</MenuItem>
<MenuItem value="DAR EL AMANE">DAR EL AMANE</MenuItem>
<MenuItem value="SOCIÉTÉ GÉNÉRALE">SOCIÉTÉ GÉNÉRALE</MenuItem>
<MenuItem value="UMNIA BANK">UMNIA BANK</MenuItem>
</Select>
</FormControl>

</Box>

<Box mt={2}>
<TextField
            autoFocus
            margin="dense"
            id="compte"
            name="compte"
            label="N° compte"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={compteField}
          />
</Box>

        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
