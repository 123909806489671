import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    apercuShow : false,
    ajoutsuccess : false,
    modifsuccess : false,
    current : [],
    listclients : [],
}

const Factures = createSlice({
    name : 'FacturesData',
    initialState,
    reducers :{
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },
        modifsuccess:(state,action) =>{
            state.modifsuccess=action.payload
        },
        currentelement:(state,action) =>{
            state.current=action.payload
        },
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        },
        listclients:(state,action) =>{
            state.listclients=action.payload
        },
    }
})

export const {ajoutsuccess,currentelement,modifsuccess,showapercu,listclients} = Factures.actions
export default Factures.reducer;