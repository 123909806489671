import { useEffect, useState } from 'react';
import { Alert, Collapse, Container, ListSubheader, Snackbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Outlet,Link, useNavigate } from 'react-router-dom';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import SignpostTwoToneIcon from '@mui/icons-material/SignpostTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import DiscountIcon from '@mui/icons-material/Discount';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import GradingTwoToneIcon from '@mui/icons-material/GradingTwoTone';
import RecyclingTwoToneIcon from '@mui/icons-material/RecyclingTwoTone';
import ProductionQuantityLimitsTwoToneIcon from '@mui/icons-material/ProductionQuantityLimitsTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import PointOfSaleTwoToneIcon from '@mui/icons-material/PointOfSaleTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import Ajouter from './pda/clients/Ajouter';
import {ajout,showmodal} from './pda/clients/clientSlice';
import {showpasswordmodal,modifpassword} from './login/userSlice';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import Settings from './login/settings';

export default function Home() {
  const userData = useSelector(state => state.userData);
  const userNom = userData.nom
  const typeCompte = userData.type
  const clientsData = useSelector(state => state.clientsPDAData);
  const ajoutSuccess = clientsData.ajoutOk
  const modifPasswordSuccess = userData.modifOk

  const [OpenStock, setOpenStock] = useState(false);
  const [OpenVendeur, setOpenVendeur] = useState(false);
  const [OpenTresor, setOpenTresor] = useState(false);
  const [OpenFacturation, setOpenFacturation] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch= useDispatch();

  const navigate = useNavigate();


  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  }

  const handleClickStock = () => {
    setOpenStock(!OpenStock);
  };

  const handleClickVendeur = () => {
    setOpenVendeur(!OpenVendeur);
  };
  const handleClickTresor = () => {
    setOpenTresor(!OpenTresor);
  };
  const handleClickFacturation = () => {
    setOpenFacturation(!OpenFacturation);
  };
  const ShowNewClientPDA = () =>{
    navigate('/home');
    setOpen(false);
    dispatch(showmodal(true));
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajout(false));
    dispatch(modifpassword(false));
  };

  const ChangePassword = () =>{
    dispatch(showpasswordmodal(true));
  }

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
<List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Menu
        </ListSubheader>
      }
    >
      { typeCompte !== "vendeur" ?

     <>
      <ListItemButton component={Link} to="/home" onClick={toggleDrawer(false)}>
        <ListItemIcon >
          <HomeTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Accueil"/>
      </ListItemButton>
      <Divider></Divider>
      <ListItemButton onClick={handleClickStock}>
        <ListItemIcon>
          <Inventory2TwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Gestion de stock" />
        {OpenStock ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={OpenStock} timeout="auto" unmountOnExit>
      { typeCompte === "admin" ?
      <>
      <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="fournisseurs" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <CategoryTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Fournisseurs" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="marques" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <CategoryTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Marques" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="categories" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <CategoryTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Catégories" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="produits" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <Inventory2TwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Produits" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="achats" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <ShoppingCartTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Achats" />
          </ListItemButton>
        </List>
        </>
            :
            ''
      }
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="stock" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <Inventory2TwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Stock central" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="stockcamion" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <LocalShippingTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Stock camion" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="retourstock" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <RecyclingTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Produits défectueux" />
          </ListItemButton>
        </List>
      </Collapse>
      <Divider></Divider>
      <ListItemButton onClick={handleClickVendeur}>
        <ListItemIcon>
          <PeopleAltTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Vendeurs" />
        {OpenVendeur ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
            <Collapse in={OpenVendeur} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
      { typeCompte === "admin" ?
      <>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="secteurs" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <SignpostTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Secteurs" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="vendeurs" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <PeopleAltTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Vendeurs" />
          </ListItemButton>
          </>
          :
          ""
      }
          <ListItemButton sx={{ pl: 4 }} component={Link} to="chargements" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <FileDownloadTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Chargements" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component={Link} to="retourcamion" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <RecyclingTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Retours défectueux" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component={Link} to="boncommande" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <GradingTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Bon de commande" />
          </ListItemButton>
          { typeCompte === "admin" ?
          <ListItemButton sx={{ pl: 4 }} component={Link} to="promotions" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <DiscountIcon />
            </ListItemIcon>
            <ListItemText primary="Promotions" />
          </ListItemButton>
                      :
                      ''
                }
          <ListItemButton sx={{ pl: 4 }} component={Link} to="cloturer" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <RemoveShoppingCartTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Clôtures de Journée" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component={Link} to="versements" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <PointOfSaleTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Versements" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component={Link} to="ventes" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <AddShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Ventes" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="credits" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <ProductionQuantityLimitsTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Crédits" />
          </ListItemButton>
        </List>
      </Collapse>

      <Divider></Divider>

      <ListItemButton component={Link} to="camions" onClick={toggleDrawer(false)}>
        <ListItemIcon >
          <LocalShippingTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Véhicules"/>
      </ListItemButton>

      <Divider></Divider>

      <ListItemButton component={Link} to="clients" onClick={toggleDrawer(false)}>
        <ListItemIcon >
          <StorefrontTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Clients"/>
      </ListItemButton>
      <Divider></Divider>
      <ListItemButton onClick={handleClickTresor}>
        <ListItemIcon>
          <PaymentsTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Trésorerie" />
        {OpenTresor ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={OpenTresor} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="caisse" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <PointOfSaleTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Caisse" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="cheques" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <ReceiptLongTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Chèques" />
          </ListItemButton>
        </List>
      </Collapse>

      <Divider></Divider>

      { typeCompte === "admin" ?
      <>
      
      <ListItemButton onClick={handleClickFacturation}>
        <ListItemIcon>
          <FileCopyTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Facturation" />
        {OpenFacturation ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={OpenFacturation} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="factures" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <DescriptionTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Factures" />
          </ListItemButton>
        </List>
      </Collapse>
      </>
      :
      ""
}

      </>
          : 
          <>
          <ListItemButton component={Link} to="/home" onClick={toggleDrawer(false)}>
            <ListItemIcon >
              <HomeTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Accueil"/>
          </ListItemButton>
          <Divider></Divider>
          <ListItemButton component={Link} to="PDArechercheclient" onClick={toggleDrawer(false)}>
            <ListItemIcon >
              <AddShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Nouvelle vente"/>
          </ListItemButton>
          <Divider></Divider>
          <ListItemButton onClick={ShowNewClientPDA}>
        <ListItemIcon >
          <StorefrontTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Nouveau client"/>
      </ListItemButton>
      <Divider></Divider>
          <ListItemButton component={Link} to="PDAstock" onClick={toggleDrawer(false)}>
            <ListItemIcon >
              <Inventory2TwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Stock"/>
          </ListItemButton>
          <Divider></Divider>
          <ListItemButton component={Link} to="PDApromotion" onClick={toggleDrawer(false)}>
            <ListItemIcon >
              <DiscountIcon />
            </ListItemIcon>
            <ListItemText primary="Promotions"/>
          </ListItemButton>
          <Divider></Divider>
          <ListItemButton component={Link} to="PDAventes" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <AddShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Ventes" />
          </ListItemButton>
          <Divider></Divider>
          <ListItemButton component={Link} to="PDAcredits" onClick={toggleDrawer(false)}>
            <ListItemIcon>
              <ProductionQuantityLimitsTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Crédits" />
          </ListItemButton>

          </>
      }
    </List>

    </Box>
  );

  useEffect(() =>{
    if(userData.id===""){
      navigate('/');
    }
  }
  ,[])
  return( 
    <Container maxWidth="xl" disableGutters >

<Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Oriental Intermédiaire
          </Typography>
          <Button color="inherit" onClick={ChangePassword}>{userNom}</Button>
        </Toolbar>
      </AppBar>
    </Box>

<div>
      <Drawer open={open}  onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
    
    <Outlet/>

    <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Client ajouté avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifPasswordSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Mot de passe modifié avec succès
        </Alert>
      </Snackbar>
    <Ajouter/>
    <Settings/>
      </Container>
);
}
